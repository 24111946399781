import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Timestamp: { input: number; output: number; }
};

export enum CacheControlScope {
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type CheckoutSession = {
  __typename?: 'CheckoutSession';
  id: Scalars['ID']['output'];
  url: Scalars['String']['output'];
};

export enum Currency {
  Eur = 'eur',
  Sek = 'sek',
  Usd = 'usd'
}

export type DetailedSpeedResult = {
  __typename?: 'DetailedSpeedResult';
  clicks: Array<Scalars['Timestamp']['output']>;
  clicksPerSecond: Scalars['Float']['output'];
  count: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  creator: User;
  eventDefinition: EventDefinition;
  id: Scalars['ID']['output'];
  jumpsLost: Scalars['Int']['output'];
  maxClicksPerSecond: Scalars['Float']['output'];
  misses: Scalars['Int']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export enum Discipline {
  DoubleDutch = 'DoubleDutch',
  SingleRope = 'SingleRope',
  Wheel = 'Wheel'
}

export type EventDefinition = {
  __typename?: 'EventDefinition';
  eventDefinitionLookupCode?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  totalDuration: Scalars['Int']['output'];
};

export type EventDefinitionInput = {
  name: Scalars['String']['input'];
  totalDuration: Scalars['Int']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  createCheckoutSession: CheckoutSession;
  createSpeedResult: SpeedResult;
  createTrickCompletion: TrickCompletion;
  deleteSpeedResult: SpeedResult;
  deleteTrickCompletion?: Maybe<TrickCompletion>;
  updateSpeedResult: SpeedResult;
};


export type MutationCreateCheckoutSessionArgs = {
  currency: Currency;
  products: Array<ProductInput>;
};


export type MutationCreateSpeedResultArgs = {
  data: SpeedResultInput;
};


export type MutationCreateTrickCompletionArgs = {
  trickId: Scalars['ID']['input'];
};


export type MutationDeleteSpeedResultArgs = {
  speedResultId: Scalars['ID']['input'];
};


export type MutationDeleteTrickCompletionArgs = {
  trickId: Scalars['ID']['input'];
};


export type MutationUpdateSpeedResultArgs = {
  data: SpeedResultUpdateInput;
  speedResultId: Scalars['ID']['input'];
};

export type Price = {
  __typename?: 'Price';
  currency: Currency;
  id: Scalars['ID']['output'];
  unitAmount?: Maybe<Scalars['Int']['output']>;
};

export type Product = {
  __typename?: 'Product';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  prices: Array<Price>;
};

export type ProductInput = {
  productId: Scalars['String']['input'];
  quantity: Scalars['Int']['input'];
};

export type ProfileOptions = {
  __typename?: 'ProfileOptions';
  checklist: Scalars['Boolean']['output'];
  public: Scalars['Boolean']['output'];
  speed: Scalars['Boolean']['output'];
};

export type Query = {
  __typename?: 'Query';
  eventDefinitions: Array<EventDefinition>;
  me?: Maybe<User>;
  products: Array<Product>;
  shippingRates: Array<Price>;
  trick?: Maybe<Trick>;
  trickBySlug?: Maybe<Trick>;
  tricks: Array<Trick>;
};


export type QueryTrickArgs = {
  id: Scalars['ID']['input'];
};


export type QueryTrickBySlugArgs = {
  discipline: Discipline;
  slug: Scalars['String']['input'];
};


export type QueryTricksArgs = {
  discipline?: InputMaybe<Discipline>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
};

export type SimpleSpeedResult = {
  __typename?: 'SimpleSpeedResult';
  count: Scalars['Int']['output'];
  createdAt: Scalars['Timestamp']['output'];
  creator: User;
  eventDefinition: EventDefinition;
  id: Scalars['ID']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

export type SpeedResult = DetailedSpeedResult | SimpleSpeedResult;

export type SpeedResultInput = {
  clicks?: InputMaybe<Array<Scalars['Timestamp']['input']>>;
  count: Scalars['Int']['input'];
  eventDefinition?: InputMaybe<EventDefinitionInput>;
  eventDefinitionId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type SpeedResultUpdateInput = {
  eventDefinition?: InputMaybe<EventDefinitionInput>;
  eventDefinitionId?: InputMaybe<Scalars['ID']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

export type Trick = {
  __typename?: 'Trick';
  createdAt?: Maybe<Scalars['Timestamp']['output']>;
  discipline: Discipline;
  id: Scalars['ID']['output'];
  levels: Array<TrickLevel>;
  localisation?: Maybe<TrickLocalisation>;
  prerequisiteFor: Array<Trick>;
  prerequisites: Array<Trick>;
  slug: Scalars['String']['output'];
  submitter?: Maybe<User>;
  trickType: TrickType;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
  videos: Array<Video>;
};


export type TrickLevelsArgs = {
  organisation?: InputMaybe<Scalars['String']['input']>;
  rulesVersion?: InputMaybe<Scalars['String']['input']>;
};


export type TrickLocalisationArgs = {
  lang?: InputMaybe<Scalars['String']['input']>;
};

export type TrickCompletion = {
  __typename?: 'TrickCompletion';
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  trick: Trick;
};

export type TrickLevel = {
  __typename?: 'TrickLevel';
  createdAt: Scalars['Timestamp']['output'];
  id: Scalars['ID']['output'];
  level: Scalars['String']['output'];
  organisation: Scalars['String']['output'];
  rulesVersion?: Maybe<Scalars['String']['output']>;
  trick: Trick;
  updatedAt: Scalars['Timestamp']['output'];
  verificationLevel?: Maybe<VerificationLevel>;
};

export type TrickLocalisation = {
  __typename?: 'TrickLocalisation';
  alternativeNames?: Maybe<Array<Scalars['String']['output']>>;
  createdAt?: Maybe<Scalars['Timestamp']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  submitter?: Maybe<User>;
  updatedAt?: Maybe<Scalars['Timestamp']['output']>;
};

export enum TrickType {
  Basic = 'basic',
  Impossible = 'impossible',
  Manipulation = 'manipulation',
  Multiple = 'multiple',
  Power = 'power',
  Release = 'release'
}

export type User = {
  __typename?: 'User';
  checklist: Array<TrickCompletion>;
  id: Scalars['ID']['output'];
  lang?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  photo?: Maybe<Scalars['String']['output']>;
  profile: ProfileOptions;
  speedResult?: Maybe<SpeedResult>;
  speedResults: Array<SpeedResult>;
  username?: Maybe<Scalars['String']['output']>;
};


export type UserSpeedResultArgs = {
  speedResultId: Scalars['ID']['input'];
};


export type UserSpeedResultsArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  startAfter?: InputMaybe<Scalars['Timestamp']['input']>;
};

export enum VerificationLevel {
  Judge = 'JUDGE',
  Official = 'OFFICIAL'
}

export type Video = {
  __typename?: 'Video';
  host: VideoHost;
  slowMoStart?: Maybe<Scalars['Float']['output']>;
  type: VideoType;
  videoId: Scalars['String']['output'];
};

export enum VideoHost {
  YouTube = 'YouTube'
}

export enum VideoType {
  Explainer = 'Explainer',
  SlowMo = 'SlowMo'
}

export type TrickBoxFragment = { __typename?: 'Trick', id: string, slug: string, trickType: TrickType, discipline: Discipline, en?: { __typename?: 'TrickLocalisation', id: string, name: string } | null, localised?: { __typename?: 'TrickLocalisation', id: string, name: string } | null, ttLevels: Array<{ __typename?: 'TrickLevel', id: string, level: string }> };

export type CompleteTrickMutationVariables = Exact<{
  trickId: Scalars['ID']['input'];
  completed: Scalars['Boolean']['input'];
}>;


export type CompleteTrickMutation = { __typename?: 'Mutation', createTrickCompletion?: { __typename?: 'TrickCompletion', id: string, trick: { __typename?: 'Trick', id: string } }, deleteTrickCompletion?: { __typename?: 'TrickCompletion', id: string } | null };

export type CreateCheckoutSessionMutationVariables = Exact<{
  products: Array<ProductInput> | ProductInput;
  currency: Currency;
}>;


export type CreateCheckoutSessionMutation = { __typename?: 'Mutation', createCheckoutSession: { __typename?: 'CheckoutSession', id: string, url: string } };

export type MeQueryVariables = Exact<{
  withChecklist: Scalars['Boolean']['input'];
}>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, username?: string | null, name?: string | null, photo?: string | null, lang?: string | null, checklist?: Array<{ __typename?: 'TrickCompletion', id: string, trick: { __typename?: 'Trick', id: string } }> } | null };

export type ProductsQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsQuery = { __typename?: 'Query', products: Array<{ __typename?: 'Product', id: string, name: string, description?: string | null, image?: string | null, prices: Array<{ __typename?: 'Price', id: string, currency: Currency, unitAmount?: number | null }> }>, shippingRates: Array<{ __typename?: 'Price', id: string, currency: Currency, unitAmount?: number | null }> };

export type TrickBySlugQueryVariables = Exact<{
  discipline: Discipline;
  slug: Scalars['String']['input'];
  withLocalised: Scalars['Boolean']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
}>;


export type TrickBySlugQuery = { __typename?: 'Query', trick?: { __typename?: 'Trick', id: string, slug: string, trickType: TrickType, discipline: Discipline, en?: { __typename?: 'TrickLocalisation', id: string, name: string, alternativeNames?: Array<string> | null, description?: string | null } | null, localised?: { __typename?: 'TrickLocalisation', id: string, name: string, alternativeNames?: Array<string> | null, description?: string | null } | null, videos: Array<{ __typename?: 'Video', videoId: string, type: VideoType, host: VideoHost }>, ijruLevels: Array<{ __typename?: 'TrickLevel', id: string, level: string, rulesVersion?: string | null, verificationLevel?: VerificationLevel | null }>, prerequisites: Array<{ __typename?: 'Trick', id: string, slug: string, trickType: TrickType, discipline: Discipline, en?: { __typename?: 'TrickLocalisation', id: string, name: string } | null, localised?: { __typename?: 'TrickLocalisation', id: string, name: string } | null, ttLevels: Array<{ __typename?: 'TrickLevel', id: string, level: string }> }>, prerequisiteFor: Array<{ __typename?: 'Trick', id: string, slug: string, trickType: TrickType, discipline: Discipline, en?: { __typename?: 'TrickLocalisation', id: string, name: string } | null, localised?: { __typename?: 'TrickLocalisation', id: string, name: string } | null, ttLevels: Array<{ __typename?: 'TrickLevel', id: string, level: string }> }>, ttLevels: Array<{ __typename?: 'TrickLevel', id: string, level: string }> } | null };

export type TricksQueryVariables = Exact<{
  discipline?: InputMaybe<Discipline>;
  searchQuery?: InputMaybe<Scalars['String']['input']>;
  withLocalised: Scalars['Boolean']['input'];
  lang?: InputMaybe<Scalars['String']['input']>;
}>;


export type TricksQuery = { __typename?: 'Query', tricks: Array<{ __typename?: 'Trick', id: string, slug: string, trickType: TrickType, discipline: Discipline, en?: { __typename?: 'TrickLocalisation', id: string, name: string } | null, localised?: { __typename?: 'TrickLocalisation', id: string, name: string } | null, ttLevels: Array<{ __typename?: 'TrickLevel', id: string, level: string }> }> };

export const TrickBoxFragmentDoc = gql`
    fragment TrickBox on Trick {
  id
  slug
  trickType
  discipline
  en: localisation {
    id
    name
  }
  localised: localisation(lang: $lang) @include(if: $withLocalised) {
    id
    name
  }
  ttLevels: levels(organisation: "tricktionary") {
    id
    level
  }
}
    `;
export const CompleteTrickDocument = gql`
    mutation CompleteTrick($trickId: ID!, $completed: Boolean!) {
  createTrickCompletion(trickId: $trickId) @include(if: $completed) {
    id
    trick {
      id
    }
  }
  deleteTrickCompletion(trickId: $trickId) @skip(if: $completed) {
    id
  }
}
    `;

/**
 * __useCompleteTrickMutation__
 *
 * To run a mutation, you first call `useCompleteTrickMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCompleteTrickMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCompleteTrickMutation({
 *   variables: {
 *     trickId: // value for 'trickId'
 *     completed: // value for 'completed'
 *   },
 * });
 */
export function useCompleteTrickMutation(options: VueApolloComposable.UseMutationOptions<CompleteTrickMutation, CompleteTrickMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CompleteTrickMutation, CompleteTrickMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CompleteTrickMutation, CompleteTrickMutationVariables>(CompleteTrickDocument, options);
}
export type CompleteTrickMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CompleteTrickMutation, CompleteTrickMutationVariables>;
export const CreateCheckoutSessionDocument = gql`
    mutation CreateCheckoutSession($products: [ProductInput!]!, $currency: Currency!) {
  createCheckoutSession(products: $products, currency: $currency) {
    id
    url
  }
}
    `;

/**
 * __useCreateCheckoutSessionMutation__
 *
 * To run a mutation, you first call `useCreateCheckoutSessionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useCreateCheckoutSessionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useCreateCheckoutSessionMutation({
 *   variables: {
 *     products: // value for 'products'
 *     currency: // value for 'currency'
 *   },
 * });
 */
export function useCreateCheckoutSessionMutation(options: VueApolloComposable.UseMutationOptions<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>(CreateCheckoutSessionDocument, options);
}
export type CreateCheckoutSessionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<CreateCheckoutSessionMutation, CreateCheckoutSessionMutationVariables>;
export const MeDocument = gql`
    query Me($withChecklist: Boolean!) {
  me {
    id
    username
    name
    photo
    lang
    checklist @include(if: $withChecklist) {
      id
      trick {
        id
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a Vue component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useMeQuery({
 *   withChecklist: // value for 'withChecklist'
 * });
 */
export function useMeQuery(variables: MeQueryVariables | VueCompositionApi.Ref<MeQueryVariables> | ReactiveFunction<MeQueryVariables>, options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<MeQuery, MeQueryVariables>(MeDocument, variables, options);
}
export function useMeLazyQuery(variables?: MeQueryVariables | VueCompositionApi.Ref<MeQueryVariables> | ReactiveFunction<MeQueryVariables>, options: VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<MeQuery, MeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, variables, options);
}
export type MeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<MeQuery, MeQueryVariables>;
export const ProductsDocument = gql`
    query Products {
  products {
    id
    name
    description
    image
    prices {
      id
      currency
      unitAmount
    }
  }
  shippingRates {
    id
    currency
    unitAmount
  }
}
    `;

/**
 * __useProductsQuery__
 *
 * To run a query within a Vue component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProductsQuery();
 */
export function useProductsQuery(options: VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, {}, options);
}
export function useProductsLazyQuery(options: VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, {}, options);
}
export type ProductsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProductsQuery, ProductsQueryVariables>;
export const TrickBySlugDocument = gql`
    query TrickBySlug($discipline: Discipline!, $slug: String!, $withLocalised: Boolean!, $lang: String) {
  trick: trickBySlug(discipline: $discipline, slug: $slug) {
    ...TrickBox
    en: localisation {
      id
      name
      alternativeNames
      description
    }
    localised: localisation(lang: $lang) @include(if: $withLocalised) {
      id
      name
      alternativeNames
      description
    }
    videos {
      videoId
      type
      host
    }
    ijruLevels: levels(organisation: "ijru") {
      id
      level
      rulesVersion
      verificationLevel
    }
    prerequisites {
      ...TrickBox
    }
    prerequisiteFor {
      ...TrickBox
    }
  }
}
    ${TrickBoxFragmentDoc}`;

/**
 * __useTrickBySlugQuery__
 *
 * To run a query within a Vue component, call `useTrickBySlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrickBySlugQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTrickBySlugQuery({
 *   discipline: // value for 'discipline'
 *   slug: // value for 'slug'
 *   withLocalised: // value for 'withLocalised'
 *   lang: // value for 'lang'
 * });
 */
export function useTrickBySlugQuery(variables: TrickBySlugQueryVariables | VueCompositionApi.Ref<TrickBySlugQueryVariables> | ReactiveFunction<TrickBySlugQueryVariables>, options: VueApolloComposable.UseQueryOptions<TrickBySlugQuery, TrickBySlugQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TrickBySlugQuery, TrickBySlugQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TrickBySlugQuery, TrickBySlugQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TrickBySlugQuery, TrickBySlugQueryVariables>(TrickBySlugDocument, variables, options);
}
export function useTrickBySlugLazyQuery(variables?: TrickBySlugQueryVariables | VueCompositionApi.Ref<TrickBySlugQueryVariables> | ReactiveFunction<TrickBySlugQueryVariables>, options: VueApolloComposable.UseQueryOptions<TrickBySlugQuery, TrickBySlugQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TrickBySlugQuery, TrickBySlugQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TrickBySlugQuery, TrickBySlugQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TrickBySlugQuery, TrickBySlugQueryVariables>(TrickBySlugDocument, variables, options);
}
export type TrickBySlugQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TrickBySlugQuery, TrickBySlugQueryVariables>;
export const TricksDocument = gql`
    query Tricks($discipline: Discipline, $searchQuery: String, $withLocalised: Boolean!, $lang: String) {
  tricks(discipline: $discipline, searchQuery: $searchQuery) {
    ...TrickBox
  }
}
    ${TrickBoxFragmentDoc}`;

/**
 * __useTricksQuery__
 *
 * To run a query within a Vue component, call `useTricksQuery` and pass it any options that fit your needs.
 * When your component renders, `useTricksQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTricksQuery({
 *   discipline: // value for 'discipline'
 *   searchQuery: // value for 'searchQuery'
 *   withLocalised: // value for 'withLocalised'
 *   lang: // value for 'lang'
 * });
 */
export function useTricksQuery(variables: TricksQueryVariables | VueCompositionApi.Ref<TricksQueryVariables> | ReactiveFunction<TricksQueryVariables>, options: VueApolloComposable.UseQueryOptions<TricksQuery, TricksQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TricksQuery, TricksQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TricksQuery, TricksQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TricksQuery, TricksQueryVariables>(TricksDocument, variables, options);
}
export function useTricksLazyQuery(variables?: TricksQueryVariables | VueCompositionApi.Ref<TricksQueryVariables> | ReactiveFunction<TricksQueryVariables>, options: VueApolloComposable.UseQueryOptions<TricksQuery, TricksQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TricksQuery, TricksQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TricksQuery, TricksQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TricksQuery, TricksQueryVariables>(TricksDocument, variables, options);
}
export type TricksQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TricksQuery, TricksQueryVariables>;